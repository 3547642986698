import { useState, useEffect } from "react";
import { Col, Row, Alert } from "react-bootstrap";

export const Newsletter = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (status === 'success') clearFields();
  }, [status])

  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
    email.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email
    })
  }

  const clearFields = () => {
    setEmail('');
  }

  return (
      <Col lg={12}>
        <div className="newsletter-bx wow slideInUp">
          <Row>
            <Col lg={12} md={6} xl={5}>
              <h3>Connect & Contact<br></br> Us Here :</h3>
              {status === 'sending' && <Alert>Sending...</Alert>}
              {status === 'error' && <Alert variant="danger">{message}</Alert>}
              {status === 'success' && <Alert variant="success">{message}</Alert>}
            </Col>
            <Col md={6} xl={7}>
            
              <form onSubmit={handleSubmit}>
                <div className="new-email-bx">
                <div className="wrapper">
                <div className="icon whatsapp">
            <div className="tooltip">
           Whatsapp
            </div>
            <span> <a href="https://wa.me/message/T7WV7OSB6XURD1/"><i className="fab fa-whatsapp"></i></a></span>
         </div>
         <div className="icon instagram">
            <div className="tooltip">
           Instagram
            </div>
            <span> <a href="https://www.instagram.com/lensanand/"><i className="fab fa-instagram"></i></a></span>
         </div>
         <div className="icon youtube">
            <div className="tooltip">
              YouTube
            </div>
            <span><a href="https://www.youtube.com/@lensanand_"><i className="fab fa-youtube"></i></a></span>
         </div>
         <div class="icon linkedin">
            <div className="tooltip">
           LinkedIn
            </div>
            <span> <a href="https://www.linkedin.com/in/anandy77"><i className="fab fa-linkedin"></i></a></span>
         </div>
        
         <div className="icon github">
            <div className="tooltip">
          Github
            </div>
            <span>  <a href="https://github.com/anandy77"><i className="fab fa-github"></i></a></span>
         </div>
         
      </div>
                </div>
              </form>
              
            </Col>
            
          </Row>
        </div>
        <div className="raman">
        <h5>Mail Me Here :<br></br>team@lensanand.com</h5>
          <h4>For More Details<br></br>Contact Here<br></br> <br></br><a href="https://wa.me/+919452367998">Raman Yadav</a></h4>
          </div>
      </Col>

      
  )
}
